import Cookie from "js-cookie";
import Template7 from "template7/dist/template7";
import './style.scss';

var el = document.querySelector('[data-cookie-bar]')
var defs = {
  privacy_text: "We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you are happy with our {{privacy_link}}",
  privacy_link: '#!',
  privacy_link_text: "Privacy Policy",
  privacy_button_text: 'Accepts',
  privacy_link_target: "_self"
}
var data = {
  ...defs,
  privacy_text: el.getAttribute('data-cookie-bar-text'),
  privacy_link: el.getAttribute('data-cookie-bar-link'),
  privacy_link_text: el.getAttribute('data-cookie-bar-link-text'),
  privacy_button_text: el.getAttribute('data-cookie-bar-button-text'),
  privacy_link_target: el.getAttribute('data-cookie-bar-link-target'),
}

var text = Template7.compile(data.privacy_text)
var compiled = text({
  privacy_link: `<a class="link" href="${data.privacy_link}" target="${data.privacy_link_target}">${data.privacy_link_text}</a>`
})

var cookie_accept = ( expire = 30 ) => {
  Cookie.set('cookie_accepted', true, {
    expires: expire
  });
}

var cookie_decline = () => {
  Cookie.remove('cookie_accepted');
}

var cookie_accepted = () => {
  return Cookie.get('cookie_accepted') == 'true';
}

var CookieBar = /*html*/`
<div class="cookie-bar">
  <div class="cookie-bar__container">
    <div class="cookie-bar__text">${compiled}</div>
    <div class="cookie-bar__button">
      <button class="button" id="cookie_accept" onclick="window.AcceptedCookie()">${data.privacy_button_text}</button>
    </div>
  </div>
</div>
`;

if ('undefined' === typeof window.AcceptedCookie) {
  window.AcceptedCookie = cookie_accept;
}

if ('undefined' === typeof window.DeclieCookie) {
  window.DeclieCookie = cookie_decline;
}

if (!cookie_accepted()) {
  document.body.insertAdjacentHTML('beforeend', CookieBar);
  var cookiebar = document.querySelector('.cookie-bar');
  var cookiebutton = cookiebar.querySelector('#cookie_accept');
  setTimeout(() => {
    cookiebar.classList.add('cookie-bar--open');
    cookiebutton.addEventListener('click', ev => {
      cookiebar.classList.remove('cookie-bar--open');
      setTimeout(() => {
        cookiebar.remove()
      }, 200)
    })
  }, 200)
}